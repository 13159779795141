import { useState } from 'react';

import { useFormik } from 'formik';

import { Step } from '@ui';

import { useContractNumberQuery, usePaySalom, useSendOtpQuery } from './api';
import { Conditions, RegistrationForm, Sms } from './components';
import { validationSchemaFormSalom } from './schema';
import { IFormSalomParams, IFormSalomProps } from './types';

export const FormSalom = ({ checkout, isTokenization }: IFormSalomProps) => {
  const [initialStep, setInitialStep] = useState<number>(0);
  const [creditConditionId, setCreditConditionId] = useState<number | null>(
    null
  );

  const {
    mutate: checkContractNumber,
    data,
    isPending,
  } = useContractNumberQuery(isTokenization);

  const {
    mutate: sendOtp,
    isPending: isPendingSendOtp,
    data: dataOtp,
  } = useSendOtpQuery();

  const { mutate: pay, isPending: isPendingPay } = usePaySalom(isTokenization);

  const handleNextStep = (): void => {
    setInitialStep(initialStep + 1);
  };

  const { handleSubmit, values, setFieldValue, errors, isValid } =
    useFormik<IFormSalomParams>({
      initialValues: { contractNumber: '' },
      validationSchema: validationSchemaFormSalom,
      onSubmit: () => {
        null;
      },
    });

  const handleChangeCreditConditionId = (id: number): void => {
    setCreditConditionId(id);
  };

  return (
    <div className="-ml-[150px] -mr-[150px] mt-5 rounded-xl bg-white p-24 sm:m-auto sm:rounded-none sm:px-6 sm:py-5">
      <Step
        initialStep={initialStep}
        steps={[
          <RegistrationForm
            key="registrationForm"
            values={values}
            isTokenization={isTokenization}
            errors={errors}
            isValid={isValid}
            checkout={checkout}
            isLoading={isPending}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            handleNextStep={handleNextStep}
            checkContractNumber={checkContractNumber}
          />,
          <Conditions
            key="conditions"
            sendOtp={sendOtp}
            handleNextStep={handleNextStep}
            contractNumber={values.contractNumber}
            isPendingSendOtp={isPendingSendOtp}
            conditions={data?.payload?.conditions}
            conditionId={Number(creditConditionId)}
            clientId={Number(data?.payload?.clientId)}
            handleChangeCreditConditionId={handleChangeCreditConditionId}
          />,
          <Sms
            key="sms"
            pay={pay}
            isTokenization={isTokenization}
            sendOtp={sendOtp}
            isPendingPay={isPendingPay}
            isLoading={isPendingSendOtp}
            phone={String(data?.payload?.phone || '')}
            clientId={Number(data?.payload?.clientId)}
            contractNumber={Number(values.contractNumber)}
            creditConditionId={Number(creditConditionId)}
            creditApplicationId={Number(dataOtp?.payload?.creditApplicationId)}
          />,
        ]}
      />
    </div>
  );
};
