import { AlifIcon } from '@icons';
import { useFormik } from 'formik';

import { Button, If, InfoPayment, Input } from '@ui';
import { checkFeeInfo, numberFormat } from '@utils';
import { ITransactionType } from '@entities/checkout';

import { validationSchemaFormMobi } from './schema';
import { IRegFormMobi, IRegistrationForm } from './types';

import { wallet } from '../../constants';

export const RegistrationForm = ({
  handleSetValuePhone,
  handleNextStep,
  checkMobi,
  isLoading,
  checkout,
  typeTransaction,
  isTokenization = false,
}: IRegistrationForm) => {
  const { values, setFieldValue, handleSubmit, isValid, errors } =
    useFormik<IRegFormMobi>({
      initialValues: { phone: '+992 ' },
      validationSchema: validationSchemaFormMobi,
      onSubmit: (values) => {
        checkMobi(
          {
            ...values,
            gate: wallet,
          },
          {
            onSuccess: (data) => {
              if (data.code === 200) {
                handleNextStep();
              }
            },
          }
        );
      },
    });

  const handleChange = (value: string): void => {
    handleSetValuePhone(value);
    setFieldValue('phone', value);
  };

  const infoPay: ITransactionType | undefined =
    checkout?.transactionTypes?.find(
      (transactionType) => transactionType.groupName === typeTransaction
    );

  const min: string = checkFeeInfo('min', String(infoPay?.feeInfo.min), ',');
  const max: string = checkFeeInfo('max', String(infoPay?.feeInfo.max));
  const infoStatic: string = checkFeeInfo(
    ' +',
    String(infoPay?.feeInfo.static),
    ','
  );

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-5">
        <Input
          maskChar=" "
          placeholder="+992 "
          mask="+999 999 99 99 99"
          label="Номер телефона"
          classNameEndIcon="!top-3"
          initialValue={values.phone}
          errorText={errors.phone}
          className="w-[70%] sm:w-full"
          isError={Boolean(errors.phone)}
          onChange={(value) => handleChange(value)}
          endIcon={<AlifIcon className="h-8 w-8 sm:h-6 sm:w-6" />}
        />
        <If condition={!isTokenization}>
          <InfoPayment
            className="mb-4"
            info={[
              {
                label: 'Комиссия',
                value: `${numberFormat(Number(infoPay?.totalFee))} c. ( ${infoPay?.feeInfo?.percent}%${infoStatic} ${min} ${max})`,
              },
              {
                label: 'Сумма к зачислению',
                value: `${numberFormat(Number(checkout?.amount))} с.`,
              },
              {
                label: 'К оплате',
                value: `${numberFormat(Number(infoPay?.totalAmount))} с.`,
              },
            ]}
          />
        </If>
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={isLoading}
          className="justify-center sm:w-full"
        >
          Далее
        </Button>
      </form>
    </>
  );
};
