import { instance } from '@configs';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { IResponse } from '@types';

import { EndpointsPhonesQueryKeys, tokenizationOperator } from './constants';
import { ICheckPhoneParams, ISendOtpParams } from './types';

export const useCheckPhoneQuery = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (
      params: ICheckPhoneParams
    ): Promise<IResponse<ICheckPhoneParams>> => {
      const { data } = await instance.post(
        isTokenization
          ? EndpointsPhonesQueryKeys.TokenizeCheck
          : EndpointsPhonesQueryKeys.Check,
        params,
        {
          headers: {
            Gate: tokenizationOperator,
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
  });
};

export const useSendOtpQuery = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (params: ISendOtpParams) => {
      const { data } = await instance.post(
        isTokenization
          ? EndpointsPhonesQueryKeys.TokenizationCommit
          : EndpointsPhonesQueryKeys.Pay,
        isTokenization ? { otp: params.otp } : params,
        {
          headers: {
            Gate: tokenizationOperator,
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
  });
};
