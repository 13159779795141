import { useCheckout } from '@hooks';

import { ContainerWrapper, parserTransactionsTypes, redirect } from '@utils';
import { If, Loader, Tabs } from '@ui';
import { useGetCheckoutQuery } from '@entities/checkout';
import { WebSites } from '@constants';

export const Home = () => {
  const { handleSetCheckout } = useCheckout();
  const { data: checkout, isLoading, isSuccess } = useGetCheckoutQuery();

  if (isLoading) {
    return <Loader />;
  }

  if (!isSuccess) {
    redirect(WebSites.AlifTj);
  }

  const [headers, childes] = parserTransactionsTypes(checkout);

  if (isSuccess) {
    handleSetCheckout(checkout);
  }

  return (
    <ContainerWrapper>
      <div className="my-[120px] sm:my-[44px]">
        <div className="mx-auto flex w-[65%] flex-col gap-10 sm:w-full sm:gap-2 sm:overflow-hidden">
          <If condition={headers.length !== 1}>
            <div className="mb-2 mt-10 sm:mt-0 sm:px-5">
              <p className="font-semibold">Выберите способ оплаты</p>
            </div>
          </If>
          <Tabs headers={headers} childes={childes} onChange={() => null} />
        </div>
      </div>
    </ContainerWrapper>
  );
};
