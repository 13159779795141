import { SalomCardIcon } from '@icons';

import { Button, If, InfoPayment, Input } from '@ui';

import { IRegistrationForm } from './types';

export const RegistrationForm = ({
  handleSubmit,
  setFieldValue,
  errors,
  isValid,
  values,
  handleNextStep,
  isLoading,
  checkout,
  checkContractNumber,
  isTokenization,
}: IRegistrationForm) => {
  const handleCheckContractNumber = (): void => {
    checkContractNumber(Number(values.contractNumber), {
      onSuccess: (data) => {
        if (data.code === 200) {
          handleNextStep();
        }
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <Input
          maskChar=" "
          mask="999999"
          placeholder="000000"
          className="w-[70%] sm:w-full"
          label="Номер карты Салом"
          errorText={errors.contractNumber}
          initialValue={values.contractNumber}
          isError={Boolean(errors.contractNumber)}
          classNameEndIcon="!top-3"
          onChange={(value) => setFieldValue('contractNumber', value)}
          endIcon={<SalomCardIcon className="h-8 w-8 sm:h-6 sm:w-6" />}
        />
        <If condition={!isTokenization}>
          <InfoPayment
            group={false}
            className="mb-4"
            info={[{ label: 'К оплате', value: `${checkout?.amount} с.` }]}
          />
        </If>
        <Button
          isLoading={isLoading}
          disabled={!isValid}
          type="submit"
          onClick={() =>
            values.contractNumber ? handleCheckContractNumber() : null
          }
          className="justify-center sm:w-full"
        >
          Далее
        </Button>
      </form>
    </>
  );
};
