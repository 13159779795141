import { toast } from 'react-toastify';
import * as htmlToImage from 'html-to-image';

export const takeScreenShot = async (node: any) => {
  const dataURI = await htmlToImage.toBlob(node);
  if (!dataURI) return;
  navigator.clipboard.write([
    new ClipboardItem({
      'image/png': dataURI,
    }),
  ]);
  toast.success('Скриншот успешно сохранен в буфер обмена');
};
