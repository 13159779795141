'use client';

import { PropsWithChildren } from 'react';

import { BeatLoader } from 'react-spinners';
import clsx from 'clsx';

import { IButtonProps } from './types';

export const Button = ({
  className,
  endIcon,
  startIcon,
  children,
  isLoading,
  disabled,
  type = 'button',
  variant = 'contained',
  onClick = () => null,
}: PropsWithChildren<Partial<IButtonProps>>) => {
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      onClick={onClick}
      className={clsx(
        'text-md flex items-center gap-2 rounded-lg px-6 py-4 text-center text-sm font-bold shadow-black transition-all duration-200 focus:outline-transparent disabled:cursor-not-allowed disabled:border-black/5 disabled:bg-black/20 disabled:text-grey-500 sm:p-3.5',
        [
          variant === 'outlined'
            ? 'border-2 border-blue-white-50 bg-transparent text-primary'
            : 'border border-primary bg-primary text-white',
        ],
        className
      )}
    >
      {startIcon} {children} {endIcon}
      {isLoading && (
        <BeatLoader
          color={clsx([disabled || isLoading ? '#73787D' : '#fff'])}
          size={6}
        />
      )}
    </button>
  );
};
