import { toast } from 'react-toastify';
import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { BASE_URL } from '@constants';
import { IResponse } from '@types';
import {
  getContentInHtml,
  getToken,
  redirect,
  setToken,
  toCamelCaseFormat,
} from '@utils';

export const instance: AxiosInstance = Axios.create({
  baseURL: BASE_URL,
});

const request = (config: InternalAxiosRequestConfig) => {
  // const token = localStorage.getItem('token');
  // if (token) {
  //   config.headers.Authorization = JSON.parse(token)?.authToken;
  // }
  config.headers.SessionToken = getToken();

  return config;
};

const response = (config: AxiosResponse<IResponse<string>>) => {
  const [token, route] = getContentInHtml(config.data as unknown as string);

  if (route) {
    redirect(route);
    setToken(token.split(':')[1]);
  }

  return toCamelCaseFormat(config);
};

const errorResponse = (config: AxiosError<IResponse<string>>) => {
  // if (config.request && config.request.status === 401) {
  // localStorage.removeItem(USER_CREDENTIALS);
  // window.location.href = '/';
  // }

  if (config.response) {
    const errorMessage: string = config.response.data.message;
    toast.error(errorMessage);
  }

  return config;
};

instance.interceptors.request.use(request);
instance.interceptors.response.use(response, errorResponse);
