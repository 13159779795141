import { useQuery } from '@tanstack/react-query';
import { instance } from '@configs';

import { IStatusTokenize } from './types';
import {
  EndpointsStatusesTokenizeQueryKeys,
  StatusTokenizeQueryKeys,
} from './constants';

export const useGetStatusTokenizeQuery = (id: string) => {
  return useQuery({
    queryFn: async (): Promise<IStatusTokenize> => {
      const { data } = await instance.get<IStatusTokenize>(
        `${EndpointsStatusesTokenizeQueryKeys.StatusTokenize}/${id}`
      );
      return data;
    },
    queryKey: [StatusTokenizeQueryKeys.StatusTokenize],
  });
};
