import { SVGProps } from 'react';

export const AlifIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5642 22.9511C11.0945 22.8119 9.71672 22.3942 8.43077 21.6981C5.99665 20.3523 4.11365 18.0783 3.24104 15.201C2.41435 12.3236 2.82769 9.3535 4.15957 6.94027C5.90479 3.69168 9.25745 1.46408 12.8397 1H12.9316L13.8501 3.32042C13.8042 3.32042 13.7583 3.32042 13.7583 3.32042C10.7271 3.50605 7.69594 5.31598 6.22628 8.05407C5.16996 9.95681 4.8944 12.2772 5.53738 14.5048C6.18035 16.7788 7.69594 18.5424 9.57894 19.5633C10.5893 20.1202 11.6916 20.4451 12.8397 20.5843C15.3657 20.8164 17.708 19.981 19.5451 18.4959C19.6369 18.4031 19.7288 18.3567 19.8206 18.2639L20.7392 20.5843C18.4887 22.3014 15.5954 23.2296 12.5642 22.9511ZM15.0442 6.42978H12.1508L8.33891 15.9435H10.819L13.6205 8.56456L16.422 15.9435H18.9021L15.0442 6.42978Z"
        fill="#00AF66"
      />
    </svg>
  );
};
