import { useMutation, useQuery } from '@tanstack/react-query';
import { instance } from '@configs';
import { toast } from 'react-toastify';

import { IResponse } from '@types';
import { toSnakeCaseFormat } from '@utils';

import { ICardParams, IParamsTokenization, IResLogo } from './types';
import { CardQueryKeys, EndpointsCardQueryKeys } from './constants';

export const getLogoCardQuery = (pan: string) => {
  return useQuery({
    queryFn: async (): Promise<IResLogo | null> => {
      if (pan.length > 15) {
        const { data } = await instance.get<IResponse<IResLogo>>(
          `${EndpointsCardQueryKeys.Logo}/${pan}`
        );
        return data.payload;
      }
      return null;
    },
    queryKey: [CardQueryKeys.Logo, pan],
  });
};

export const usePaymentCardQuery = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (params: ICardParams): Promise<IResponse<string>> => {
      const dataTokenization: IParamsTokenization = {
        cvv: params.cvv2,
        pan: params.cardNumber,
        owner: params.holderName,
        expiryYear: params.cardExpYear,
        expiryMonth: params.cardExpMonth,
      };

      const { data } = await instance.post<IResponse<string>>(
        isTokenization
          ? EndpointsCardQueryKeys.Tokenization
          : EndpointsCardQueryKeys.Pay,
        params,
        {
          transformRequest: [
            (data, headers) => {
              headers['Content-Type'] = 'application/json';
              return JSON.stringify(
                toSnakeCaseFormat(isTokenization ? dataTokenization : data)
              );
            },
          ],
        }
      );
      return data || '';
    },
    onSuccess: (data) => {
      toast.success(data.message);
      if (data.code === 1 || data.code === 200) {
        window.location.href = data?.payload;
        return;
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};
