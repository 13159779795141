import clsx from 'clsx';

import { IInfo } from '@types';

import { IInfoProps } from './types';

export const InfoPayment = ({ info, className, group = true }: IInfoProps) => {
  return (
    <div
      className={clsx(
        'border-w rounded-xl border-2 border-dashed border-grey-60 bg-grey-20 p-3',
        className
      )}
    >
      {info.map((info: IInfo) => (
        <div
          key={info.label}
          className={clsx(
            'flex items-center justify-between last:!font-semibold',
            [group ? 'border-b border-grey-30 p-2 last:border-none' : 'px-2']
          )}
        >
          <p className="text-base">{info.label}</p>
          <p className="text-base">{info.value}</p>
        </div>
      ))}
    </div>
  );
};
