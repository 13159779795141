import { useState } from 'react';

import { Step } from '@ui';

import { IFormPhonesProps } from './types';
import { RegistrationForm, Sms } from './components';
import { useCheckPhoneQuery, useSendOtpQuery } from './api';

export const FormPhones = ({
  checkout,
  typeTransaction,
  isTokenization = false,
}: IFormPhonesProps) => {
  const [phone, setPhone] = useState<string>('');
  const [initialStep, setInitialStep] = useState<number>(0);

  const handleNextStep = (): void => {
    setInitialStep(initialStep + 1);
  };

  const handlePrevStep = (): void => {
    setInitialStep(initialStep - 1);
  };

  const { mutate: checkMobi, isPending } = useCheckPhoneQuery(isTokenization);
  const { mutate: sendOtp, isPending: isPendingSendOtp } =
    useSendOtpQuery(isTokenization);

  const handleSetValuePhone = (phone: string): void => {
    setPhone(phone);
  };

  return (
    <div className="-ml-[150px] -mr-[150px] mt-5 rounded-xl bg-white p-24 sm:m-auto sm:rounded-none sm:px-6 sm:py-5">
      <Step
        initialStep={initialStep}
        steps={[
          <RegistrationForm
            key="registrationForm"
            isTokenization={isTokenization}
            isLoading={isPending}
            checkMobi={checkMobi}
            checkout={checkout || undefined}
            typeTransaction={typeTransaction}
            handleNextStep={handleNextStep}
            handleSetValuePhone={handleSetValuePhone}
          />,
          <Sms
            key="sms"
            phone={phone}
            isTokenization={isTokenization}
            sendOtp={sendOtp}
            checkMobi={checkMobi}
            isLoading={isPendingSendOtp}
            handlePrevStep={handlePrevStep}
          />,
        ]}
      />
    </div>
  );
};
