import { motion } from 'framer-motion';

import { BASE_URL_LOGOS } from '@constants';

import { IHeaderStatusProps } from './types';
import { animate } from './constants';

export const HeaderStatus = ({ logo, title }: IHeaderStatusProps) => {
  return (
    <div className="mb-8 flex flex-col items-center justify-center">
      <motion.img
        alt="Img"
        initial="initial"
        animate="animate"
        variants={animate}
        src={`${BASE_URL_LOGOS}/${logo}.svg`}
      />
      <h1 className="mt-8 text-2xl font-semibold">{title}</h1>
    </div>
  );
};
