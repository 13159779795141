import { useMutation } from '@tanstack/react-query';
import { instance } from '@configs';
import { toast } from 'react-toastify';

import { IResponse } from '@types';

import { EndpointsMobiQueryKeys, wallet } from './constants';
import { ICheckMobiParams, ISendOtpParams } from './types';

export const useCheckMobiQuery = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (
      params: ICheckMobiParams
    ): Promise<IResponse<ICheckMobiParams>> => {
      const { data } = await instance.post(
        isTokenization
          ? EndpointsMobiQueryKeys.TokenizeCheck
          : EndpointsMobiQueryKeys.Check,
        isTokenization ? { phone: params.phone } : params,
        {
          headers: {
            Gate: wallet,
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
  });
};

export const useSendOtpQuery = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (params: ISendOtpParams) => {
      const { data } = await instance.post(
        isTokenization
          ? EndpointsMobiQueryKeys.TokenizationCommit
          : EndpointsMobiQueryKeys.Pay,
        isTokenization ? { otp: params.otp } : params,
        {
          headers: {
            Gate: wallet,
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
  });
};
