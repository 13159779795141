import { useFormik } from 'formik';

import { redirect } from '@utils';
import { Button, Input, Offerta } from '@ui';
import { IResponse } from '@types';

import { validationSchemaFormMobi } from './schema';
import { IOtpFormMobi, ISmsProps } from './types';

import { wallet } from '../../constants';

export const Sms = ({
  phone,
  handlePrevStep,
  sendOtp,
  isLoading,
  isTokenization,
}: ISmsProps) => {
  // const [completedRange, setCompletedRange] = useState<number>(0);

  const { values, isValid, setFieldValue, errors, handleSubmit } =
    useFormik<IOtpFormMobi>({
      initialValues: { otp: '' },
      validationSchema: validationSchemaFormMobi,
      onSubmit: ({ otp }) => {
        sendOtp(
          { otp: String(otp), phone: phone, gate: wallet },
          {
            onSuccess: (data: IResponse<string>) => {
              if (data.code === 200) {
                redirect(data?.payload);
              }
            },
          }
        );
      },
    });

  // const handleCheckMobi = (): void => {
  //   checkMobi(
  //     {
  //       phone,
  //       gate: wallet,
  //     },
  //     {
  //       onSuccess: () => {
  //         setCompletedRange(0);
  //       },
  //     }
  //   );
  // };

  // const isSendOtp: boolean = completedRange === 300;

  // useEffect(() => {
  //   const calculateRange = (): void => {
  //     setCompletedRange((prev: number) => (prev += 1));
  //   };
  //
  //   const timeoutID = setTimeout(calculateRange, 1000);
  //
  //   return () => {
  //     clearTimeout(timeoutID);
  //   };
  // }, [completedRange]);

  return (
    <form onSubmit={handleSubmit}>
      <p>SMS с кодом подтверждения отправлен на номер {phone}</p>
      <p
        onClick={handlePrevStep}
        className="mb-8 mt-2 inline-block cursor-pointer text-primary hover:underline sm:mb-4"
      >
        Изменить номер
      </p>
      <Input
        mask="99999"
        maskChar=" "
        label="Введите код"
        placeholder="60881"
        errorText={errors.otp}
        initialValue={values.otp}
        isError={Boolean(errors.otp)}
        onChange={(value) => setFieldValue('otp', value)}
      />
      <div className="mt-4 flex flex-col items-center justify-center gap-2">
        {/*<p className="text-base text-grey-500">*/}
        {/*  Отправить SMS повторно можно через 5 мин*/}
        {/*</p>*/}
        {/*<ProgressBar*/}
        {/*  height="7px"*/}
        {/*  isLabelVisible={false}*/}
        {/*  className="my-2 w-full"*/}
        {/*  bgColor="#3899E2"*/}
        {/*  maxCompleted={300}*/}
        {/*  completed={completedRange}*/}
        {/*/>*/}
        {/*<p*/}
        {/*  onClick={() => (isSendOtp ? handleCheckMobi() : null)}*/}
        {/*  className={clsx([*/}
        {/*    isSendOtp*/}
        {/*      ? 'cursor-pointer text-primary hover:underline'*/}
        {/*      : 'cursor-not-allowed text-grey-500',*/}
        {/*  ])}*/}
        {/*>*/}
        {/*  Получить новый код*/}
        {/*</p>*/}
      </div>
      <Button
        type="submit"
        isLoading={isLoading}
        disabled={!isValid}
        className="mb-4 mt-6 w-full justify-center"
      >
        {isTokenization ? 'Привязать' : 'Оплатить'}
      </Button>
      <Offerta isTokenization={isTokenization} />
    </form>
  );
};
