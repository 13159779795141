import { useFormik } from 'formik';

import { redirect } from '@utils';
import { Button, Input, Offerta } from '@ui';

import { validationSchemaFormMobi } from './schema';
import { IOtpFormPhone, ISmsProps } from './types';

export const Sms = ({
  pay,
  phone,
  clientId,
  contractNumber,
  creditConditionId,
  isPendingPay,
  creditApplicationId,
  isTokenization,
}: ISmsProps) => {
  // const [completedRange, setCompletedRange] = useState<number>(0);

  const { values, isValid, setFieldValue, errors, handleSubmit } =
    useFormik<IOtpFormPhone>({
      initialValues: { otp: '' },
      validationSchema: validationSchemaFormMobi,
      onSubmit: ({ otp }) => {
        pay(
          {
            clientId,
            creditConditionId,
            contractNumber,
            creditApplicationId,
            confirmationCode: Number(otp),
          },
          {
            onSuccess: (data) => {
              redirect(data?.payload);
            },
          }
        );
      },
    });

  // const handleSendOtp = (): void => {
  //   sendOtp(
  //     {
  //       clientId,
  //       creditConditionId,
  //       contractNumber,
  //     },
  //     {
  //       onSuccess: () => {
  //         setCompletedRange(0);
  //       },
  //     }
  //   );
  // };

  // const isSendOtp: boolean = completedRange === 300;

  // useEffect(() => {
  //   const calculateRange = (): void => {
  //     setCompletedRange((prev: number) => (prev += 1));
  //   };
  //
  //   const timeoutID = setTimeout(calculateRange, 1000);
  //
  //   return () => {
  //     clearTimeout(timeoutID);
  //   };
  // }, [completedRange]);

  return (
    <form onSubmit={handleSubmit}>
      <p className="mb-4">
        SMS с кодом подтверждения отправлен на номер {phone}
      </p>
      <Input
        mask="99999"
        maskChar=" "
        label="Введите код"
        placeholder="60881"
        errorText={errors.otp}
        initialValue={values.otp}
        isError={Boolean(errors.otp)}
        onChange={(value) => setFieldValue('otp', value)}
      />
      <div className="mt-4 flex flex-col items-center justify-center gap-2">
        {/*<p className="text-base text-grey-500">*/}
        {/*  Отправить SMS повторно можно через 5мин*/}
        {/*</p>*/}
        {/*<ProgressBar*/}
        {/*  height="7px"*/}
        {/*  isLabelVisible={false}*/}
        {/*  className="my-2 w-full"*/}
        {/*  bgColor="#3899E2"*/}
        {/*  maxCompleted={300}*/}
        {/*  completed={completedRange}*/}
        {/*/>*/}
        {/*<p*/}
        {/*  onClick={() => (isSendOtp ? handleSendOtp() : null)}*/}
        {/*  className={clsx([*/}
        {/*    isSendOtp*/}
        {/*      ? 'cursor-pointer text-primary hover:underline'*/}
        {/*      : 'cursor-not-allowed text-grey-500',*/}
        {/*  ])}*/}
        {/*>*/}
        {/*  Получить новый код*/}
        {/*</p>*/}
      </div>
      <Button
        type="submit"
        isLoading={isPendingPay}
        className="mb-4 mt-6 w-full justify-center"
        disabled={!isValid}
      >
        {isTokenization ? 'Привязать' : 'Оплатить'}
      </Button>
      <Offerta isTokenization={isTokenization} />
    </form>
  );
};
