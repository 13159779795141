import { useQuery } from '@tanstack/react-query';
import { instance } from '@configs';

import { IResponse } from '@types';

import { CheckoutQueryKeys, EndpointsCheckoutQueryKeys } from './constants';
import { ICheckout } from './types';

export const useGetCheckoutQuery = () => {
  return useQuery({
    queryFn: async (): Promise<ICheckout> => {
      const { data } = await instance.get<IResponse<ICheckout>>(
        EndpointsCheckoutQueryKeys.Checkout
      );
      return data.payload;
    },
    queryKey: [CheckoutQueryKeys.Checkout],
  });
};

export const useGetTokenizeCheckoutQuery = () => {
  return useQuery({
    queryFn: async (): Promise<ICheckout> => {
      const { data } = await instance.get<IResponse<ICheckout>>(
        EndpointsCheckoutQueryKeys.TokenizeCheckout
      );
      return data.payload;
    },
    queryKey: [CheckoutQueryKeys.TokenizeCheckout],
  });
};
