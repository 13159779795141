import clsx from 'clsx';

import { IChipProps } from './types';

export const Chip = ({ condition, handleSelectValue, value }: IChipProps) => {
  return (
    <div
      key={condition.id}
      onClick={() => handleSelectValue(condition.id)}
      className={clsx(
        'flex w-[49%] cursor-pointer flex-col items-center justify-center rounded-xl border bg-grey-20 py-4 transition-all duration-200 hover:border-primary sm:w-full',
        [condition.id === value ? 'border-primary' : 'border-grey-20']
      )}
    >
      <p className={clsx([condition.id === value && 'font-semibold'])}>
        {condition.displayName}
      </p>
    </div>
  );
};
