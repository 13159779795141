import { useEffect, useState } from 'react';

import clsx from 'clsx';

import { Button, If } from '@ui';

import { IConditionsProps } from './types';

export const Conditions = ({
  conditions,
  clientId,
  isPendingSendOtp,
  conditionId,
  sendOtp,
  contractNumber,
  handleNextStep,
  handleChangeCreditConditionId,
}: IConditionsProps) => {
  const [value, setValue] = useState<number>();

  const handleSelectValue = (value: number): void => {
    setValue(value);
    handleChangeCreditConditionId(value);
  };

  const handleSendOtp = (): void => {
    sendOtp(
      {
        clientId,
        creditConditionId: conditionId,
        contractNumber: Number(contractNumber),
      },
      {
        onSuccess: (data) => {
          if (data.code === 200) {
            handleNextStep();
          }
        },
      }
    );
  };

  useEffect(() => {
    if (!conditions?.length) {
      handleNextStep();
    }
  }, []);

  return (
    <>
      <If condition={Boolean(conditions?.length)}>
        <p className="mb-2 text-sm text-grey-500">Выберите условия</p>
        <div className="mb-10 flex flex-wrap items-center justify-between gap-2">
          {conditions?.map((condition) => (
            <div
              key={condition.id}
              onClick={() => handleSelectValue(condition.id)}
              className={clsx(
                'flex w-[49%] cursor-pointer flex-col items-center justify-center rounded-xl border bg-grey-20 py-4 transition-all duration-200 hover:border-primary sm:w-full',
                [condition.id === value ? 'border-primary' : 'border-grey-20']
              )}
            >
              <p className={clsx([condition.id === value && 'font-semibold'])}>
                {condition.displayName}
              </p>
            </div>
          ))}
        </div>
      </If>
      <Button
        isLoading={isPendingSendOtp}
        disabled={!conditionId}
        onClick={() => (contractNumber && conditionId ? handleSendOtp() : null)}
        type="submit"
        className="w-full justify-center"
      >
        Оплатить
      </Button>
    </>
  );
};
