import { useQuery } from '@tanstack/react-query';
import { instance } from '@configs';

import { IStatus } from './types';
import { EndpointsStatusesQueryKeys, StatusQueryKeys } from './constants';

export const useGetStatusQuery = (id: string) => {
  return useQuery({
    queryFn: async (): Promise<IStatus> => {
      const { data } = await instance.get<IStatus>(
        `${EndpointsStatusesQueryKeys.Status}/${id}`
      );
      return data;
    },
    queryKey: [StatusQueryKeys.Status],
  });
};
