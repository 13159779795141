import { useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { ContainerWrapper } from '@utils';
import { Chip, useGetConditionsQuery } from '@entities/conditions-salom';
import { Button, Loader } from '@ui';

import { useApplicationQuery, useTrancheQuery } from './api';
import { IApplicationParams, ITrancheParams } from './types';

export const FormConditionsSalom = () => {
  const [searchParams] = useSearchParams();
  const id: string = searchParams.get('id') || '';

  const [valueCondition, setValueCondition] = useState<number | null>(null);

  const { data, isLoading } = useGetConditionsQuery(id);
  const { mutate: application, isPending } = useApplicationQuery();
  const { mutate: tranche, isPending: isPendingTranche } = useTrancheQuery();

  const handleApplication = (): void => {
    if (valueCondition) {
      const applicationParams: IApplicationParams = {
        id,
        conditionId: valueCondition,
        clientId: Number(data?.clientId),
      };
      application(applicationParams, {
        onSuccess: (data) => {
          const trancheParams: ITrancheParams = {
            ...applicationParams,
            applicationId: data.payload.creditApplicationId,
          };
          tranche(trancheParams);
        },
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ContainerWrapper>
      <div className="my-[120px] sm:my-[44px]">
        <div className="mx-auto flex w-[65%] flex-col gap-10 sm:w-full sm:gap-2 sm:overflow-hidden">
          <div className="mb-2 mt-10 sm:mt-0 sm:px-5">
            <p className="font-semibold">Выберите условия рассрочки</p>
          </div>
          <div className="-ml-[150px] -mr-[150px] mt-5 flex flex-col gap-4 rounded-xl bg-white p-24 sm:m-auto sm:w-full sm:rounded-none sm:px-6 sm:py-5">
            <div className="mb-10 flex flex-wrap items-center justify-between gap-2">
              {data?.conditions.map((condition) => (
                <Chip
                  value={Number(valueCondition)}
                  key={condition.id}
                  condition={condition}
                  handleSelectValue={(id) => setValueCondition(id)}
                />
              ))}
            </div>
            <Button
              type="submit"
              onClick={handleApplication}
              className="w-full justify-center"
              isLoading={isPending || isPendingTranche}
            >
              Оформить
            </Button>
          </div>
        </div>
      </div>
    </ContainerWrapper>
  );
};
