import { SVGProps } from 'react';

export const SalomCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 7.725C0 6.46488 0 5.83482 0.245235 5.35352C0.46095 4.93016 0.805157 4.58595 1.22852 4.37024C1.70982 4.125 2.33988 4.125 3.6 4.125H20.4C21.6601 4.125 22.2902 4.125 22.7715 4.37024C23.1948 4.58595 23.539 4.93016 23.7548 5.35352C24 5.83482 24 6.46488 24 7.725V16.275C24 17.5351 24 18.1652 23.7548 18.6465C23.539 19.0698 23.1948 19.414 22.7715 19.6298C22.2902 19.875 21.6601 19.875 20.4 19.875H3.6C2.33988 19.875 1.70982 19.875 1.22852 19.6298C0.805157 19.414 0.46095 19.0698 0.245235 18.6465C0 18.1652 0 17.5351 0 16.275V7.725Z"
        fill="url(#paint0_linear_571_111)"
      />
      <g filter="url(#filter0_d_571_111)">
        <path
          d="M13.2318 9.0376H11.6899L9.63184 14.0999H10.9522L12.4597 10.1903L13.9672 14.0999H15.2876L13.2318 9.0376Z"
          fill="white"
        />
        <path
          d="M11.9032 17.8184H11.8924C11.1161 17.741 10.372 17.5066 9.69892 17.1388C8.40002 16.4292 7.37209 15.2249 6.92263 13.6959C6.47318 12.1647 6.68823 10.597 7.39574 9.29812C7.76133 8.62932 8.25594 8.03363 8.86023 7.54977L8.87313 7.53902C8.87313 7.53902 8.87313 7.53902 8.87528 7.53687C9.76989 6.80355 10.8645 6.31539 12.0494 6.1627C12.0559 6.1627 12.0946 6.1584 12.101 6.15625L12.5978 7.37988C12.5913 7.37988 12.5505 7.38418 12.5419 7.38418C11.4838 7.45085 10.5032 7.84654 9.71398 8.48094C9.70107 8.49169 9.68817 8.50029 9.67742 8.51104C9.65806 8.52825 9.63871 8.5433 9.61935 8.5605L9.6172 8.56265L9.61505 8.5648C9.61075 8.56911 9.6043 8.57341 9.6 8.57771C9.1527 8.94759 8.78281 9.39705 8.5097 9.89811C7.95702 10.911 7.78928 12.1368 8.13981 13.3346C8.49034 14.5303 9.29248 15.4722 10.3054 16.0249C10.843 16.3173 11.4387 16.5023 12.058 16.5603C13.4064 16.6722 14.6795 16.2378 15.6515 15.4399C15.6988 15.4012 15.7461 15.3625 15.7913 15.3217L16.2902 16.5474C15.0816 17.4722 13.5376 17.9646 11.9075 17.8184H11.9032Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_571_111"
          x="1.625"
          y="2.625"
          width="20.75"
          height="20.75"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_571_111"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_571_111"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_571_111"
          x1="24"
          y1="19.875"
          x2="0.728287"
          y2="3.11382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D71C1F" />
          <stop offset="1" stopColor="#F76567" />
        </linearGradient>
      </defs>
    </svg>
  );
};
